import { format } from 'mathjs';
import { client } from '../../utils/axios-instance';

/**
 * Service function responsible for making a POST call to
 * upload routes file to S3
 * @param {String} fileData file body
 * @param {String} fileName file name
 * @param {Number} openArea Open area ID where the route was created
 * @param {Number} regionId ID of region where route is located
 * @param {Number} propertyId ID of property where route is located
 * @param {Number} blockId ID of block where route is located
 * @param {Number} subrowId ID of subrow where route is located
 * @returns {Object} Response object
 */
export const uploadRouteFile = async (fileData, fileName, openArea, regionId, propertyId, blockId, subrowId) => {
  const formdata = new FormData();
  formdata.append('fileData', fileData);
  formdata.append('file_name', fileName);
  formdata.append('openArea', openArea);
  formdata.append('regionId', regionId);
  formdata.append('propertyId', propertyId);
  formdata.append('blockId', blockId);
  formdata.append('routeId', subrowId);
  const response = await client.post('/file-manager/routes', formdata);
  return response.data;
};

export const listRoutes = async () => {
  const response = await client.get('/file-manager/routes');
  return response.data;
};

export const getRouteCsvData = async (csvName) => {
  const response = await client.get('/file-manager/csv-data', { params: { csv_name: csvName } });
  return response.data;
};

/**
 * Service function responsible for making a POST call
 * to upload all_paths file for open area routes
 * @param {String} fileData file body
 * @param {String} fileName file name
 * @param {Number} openArea Open area ID where the route was created
 * @param {Number} regionId ID of region where route is located
 * @param {Number} propertyId ID of property where route is located
 * @param {Number} blockId ID of block where route is located
 * @returns {Object} response object
 */
export const uploadAllPathsFile = async (fileData, openArea, regionId, propertyId, blockId) => {
  const formdata = new FormData();
  formdata.append('fileData', fileData);
  formdata.append('openArea', openArea);
  formdata.append('regionId', regionId);
  formdata.append('propertyId', propertyId);
  formdata.append('blockId', blockId);
  const response = await client.post('/file-manager/all-paths-file', formdata);
  return response.data;
};
