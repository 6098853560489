import React, { useState } from 'react';
import { Grid, AppBar, Toolbar, IconButton, makeStyles, Typography, Tooltip } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import { Link, withRouter, useHistory } from 'react-router-dom';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import { useStores } from '../../store/root/root.store';
import SwapLogo from '../../assets/Swap-Logo-Red.png';
import {
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_ADMIN_OPERATIONS,
  ROUTE_SOLAR_REMOTE,
  ROUTE_PATHS_STUDIO,
  ROUTE_AUDIT_OFFICER,
  ROUTE_AUDIT,
  ROUTE_MANAGE_ROBOTS,
  ROUTE_DIAGNOSTICS,
  ROUTE_AUTONOMY_AUDIT_REPORTS
} from '../../pages/routes';
import { USE_CASES } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.inverted.main,
    '& svg': {
      fontSize: 50
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  rightTopBar: {
    display: 'flex'
  },
  button: {
    color: theme.palette.inverted.main,
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  menuOpen: {
    color: theme.palette.secondary.main
  },
  menuItem: {
    justifyContent: 'center',
    color: '#394043',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.inverted.main
    }
  },
  logoutItem: {
    justifyContent: 'center',
    color: 'red',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.inverted.main
    }
  }
}));

const TopBar = withRouter(({ location, children }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const { authenticationStore, subsectionStore } = useStores();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorSubMenuEl, setAnchorSubMenuEl] = useState(null);
  const isAuditOfficerPage = location.pathname === ROUTE_AUDIT_OFFICER;
  const isAuthenticated = authenticationStore.isAuthenticated();
  const [subMenu, setSubMenu] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSubMenu = (currentTarget, menuItems) => {
    setSubMenu(menuItems);
    setAnchorSubMenuEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorSubMenuEl(null);
    setAnchorEl(null);
  };

  const handleCloseSubMenu = () => {
    setAnchorSubMenuEl(null);
  };

  const logout = () => {
    authenticationStore.logout();
    handleClose();
  };

  const handleUseCase = (useCase) => {
    const selectedUseCase = Object.keys(USE_CASES).find((key) => USE_CASES[key] === useCase);
    localStorage.setItem('useCase', selectedUseCase);
    handleClose();
  };

  const handleAuditOfficer = (useCase) => {
    handleUseCase(useCase);
    if (isAuditOfficerPage) window.location.reload(false);
  };

  return useObserver(() => (
    <AppBar position="static" style={{ backgroundColor: 'transparent' }}>
      <Toolbar width="100%" style={{ backgroundColor: 'transparent' }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.background}>
          <Grid item className={classes.rightTopBar}>
            <div className={classes.menuButton} onClick={() => push(ROUTE_HOME)}>
              <img style={{ marginTop: '10px', width: '144px', height: '80px' }} src={SwapLogo} alt="SWAP Logo" />
            </div>
            <Menu
              id="menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              keepMounted
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {authenticationStore.isAuthenticated()
                ? [
                    <MenuItem className={classes.menuItem} key="home" component={Link} to={ROUTE_HOME} onClick={handleClose}>
                      Home
                    </MenuItem>,
                    <MenuItem
                      className={classes.menuItem}
                      key="remote_guardian"
                      onClick={(event) =>
                        handleClickSubMenu(event.currentTarget, [
                          // { name: 'Snow Plowing', route: ROUTE_REMOTE_GUARDIAN, handler: handleUseCase, useCase: USE_CASES.SNOW_PLOWING },
                          {
                            name: 'Solar Vegetation',
                            route: ROUTE_SOLAR_REMOTE,
                            handler: handleUseCase,
                            useCase: USE_CASES.SOLAR_LAWN_MOWING
                          }
                        ])
                      }
                    >
                      Remote Guardian
                    </MenuItem>,
                    <MenuItem
                      className={classes.menuItem}
                      key="chaperone"
                      onClick={(event) =>
                        handleClickSubMenu(event.currentTarget, [
                          // { name: 'Snow Plowing', route: ROUTE_CHAPERONE, handler: handleUseCase, useCase: USE_CASES.SNOW_PLOWING },
                          {
                            name: 'Solar Vegetation',
                            route: ROUTE_PATHS_STUDIO,
                            handler: handleUseCase,
                            useCase: USE_CASES.SOLAR_LAWN_MOWING
                          }
                        ])
                      }
                    >
                      Paths Studio
                    </MenuItem>,
                    <MenuItem
                      className={classes.menuItem}
                      key="audit-reports"
                      onClick={(event) =>
                        handleClickSubMenu(event.currentTarget, [
                          { name: 'Operations Audit Reports', route: ROUTE_AUDIT, handler: handleClose },
                          { name: 'Autonomy Audit Reports', route: ROUTE_AUTONOMY_AUDIT_REPORTS, handler: handleClose }
                        ])
                      }
                      style={{
                        display: authenticationStore.role.toLowerCase() === 'admin' ? 'flex' : 'none'
                      }}
                    >
                      Audit Reports
                    </MenuItem>,
                    <MenuItem
                      className={classes.menuItem}
                      key="audit-officer"
                      style={{
                        display: authenticationStore.role.toLowerCase() === 'admin' ? 'flex' : 'none'
                      }}
                      onClick={(event) =>
                        handleClickSubMenu(event.currentTarget, [
                          {
                            name: 'Solar Auditing',
                            route: ROUTE_AUDIT_OFFICER,
                            handler: handleAuditOfficer,
                            useCase: USE_CASES.SOLAR_LAWN_MOWING
                          }
                        ])
                      }
                    >
                      Audit Officer
                    </MenuItem>,
                    <MenuItem
                      className={classes.menuItem}
                      key="add-robot"
                      component={Link}
                      to={ROUTE_MANAGE_ROBOTS}
                      onClick={handleClose}
                      style={{
                        display: authenticationStore.role.toLowerCase() === 'admin' ? 'flex' : 'none'
                      }}
                    >
                      Manage Robots
                    </MenuItem>,
                    <MenuItem className={classes.menuItem} key="diagnostics" component={Link} to={ROUTE_DIAGNOSTICS} onClick={handleClose}>
                      Diagnostics
                    </MenuItem>,
                    <MenuItem className={classes.logoutItem} key="logout" component={Link} to="/" onClick={logout}>
                      Logout
                    </MenuItem>
                  ]
                : null}
            </Menu>

            <Menu
              id="sub-menu"
              anchorEl={anchorSubMenuEl}
              getContentAnchorEl={null}
              keepMounted
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={Boolean(anchorSubMenuEl)}
              onClose={handleCloseSubMenu}
            >
              {subMenu.map(({ name, route, handler, useCase }) => (
                <MenuItem className={classes.menuItem} key={name} component={Link} to={route} onClick={() => handler(useCase)}>
                  {name}
                </MenuItem>
              ))}
            </Menu>

            {authenticationStore.isAuthenticated() ? children : null}
          </Grid>
          {location.pathname !== ROUTE_LOGIN && isAuthenticated && (
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Tooltip title={<Typography>Admin Console</Typography>}>
                    <IconButton
                      className={classes.button}
                      color="inherit"
                      component={Link}
                      to={ROUTE_ADMIN_OPERATIONS}
                      style={{ display: authenticationStore.role.toLowerCase() === 'admin' ? 'flex' : 'none' }}
                    >
                      <DashboardRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={<Typography>Menu</Typography>}>
                    <IconButton className={anchorEl ? classes.menuOpen : classes.button} color="inherit" onClick={handleClick}>
                      <MenuRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  ));
});

export default TopBar;
